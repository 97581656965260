<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Side Products</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="editItem" class="mb-2"
        >Create Side Product</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="$store.state.sideProducts.productSideProduct"
      :items-per-page="50"
      class="elevation-1"
    >
      <template v-slot:item.type="{ item }">
        <div>
          {{
            $store.state.homes.enumTypes.sideProductType.find(
              (m) => m.value == item.type
            ).id
          }}
        </div>
      </template>
      <template v-slot:item.c="{ item }">
        <div>
          {{ item.c | formatMoney }}
        </div>
      </template>
      <template v-slot:item.model="{ item }">
        <div>
          {{ item.model }}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="deleteItem(item)">delete</v-icon>
      </template>
    </v-data-table>
    <side-detail-card ref="detail" />
  </div>
</template>
<script>
import SideDetailCard from "./SideDetailCard.vue";

export default {
  components: { SideDetailCard },

  data() {
    return {
      sideProductId: null,
      id: null,
      offerId: null,
      headers: [
        {
          text: "id",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "Type", value: "type" },
        { text: "ProductId", value: "productId" },
        { text: "OfferId", value: "offerId" },
        { text: "MinSpend", value: "minSpend" },
        { text: "MaxSpend", value: "maxSpend" },
        { text: "Model", value: "model" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    async deleteItem(item) {
      await this.$store.dispatch("sideProducts/delete", item.id);
      setTimeout(async () => {
        await this.initialize(this.id, this.offerId);
      }, 200);
    },
    editItem(item) {
      this.dialog = true;
      this.sideProductId = item.id;
      this.$refs.detail.initialize(item.id, this.id, this.offerId);
    },
    async initialize(id, offerId) {
      this.id = id;
      this.offerId = offerId;
      await this.$store.dispatch("sideProducts/getbyProductOrOfferId", {
        id: id,
        offerId: offerId,
      });
    },
  },

  async mounted() {},
};
</script>
