<template>
  <div>
    <v-dialog
      v-model="dialog"
      class="deneme"
      min-width="900px"
      max-width="1500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Side Product</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeBtn">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <side-detail-list-card ref="detail" :id="id" :offerId="offerId" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SideDetailListCard from "./SideDetailListCard.vue";
export default {
  components: { SideDetailListCard },
  data() {
    return {
      dialog: false,
      id: null,
      offerId: null,
      callback: null,
    };
  },
  methods: {
    async closeBtn() {
      this.dialog = false;
      this.callback();
    },
    initialize(id, offerId, callback) {
      this.callback = callback;
      this.id = id;
      this.offerId = offerId;
      this.dialog = true;
      // eslint-disable-next-line no-console
      console.log(id);
      this.$nextTick(() => {
        this.$refs.detail.initialize(id, this.offerId);
      });
    },
  },
};
</script>
