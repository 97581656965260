export default [
  { value: "Chip", key: 0 },
  { value: "Gold", key: 1 },
  { value: "Multi", key: 3 },
  { value: "Noel", key: 4 },
  { value: "Slot", key: 5 },
  { value: "Lottery", key: 6 },
  { value: "Easter", key: 7 },
  { value: "TrioWheel", key: 8 },
  { value: "RushPass", key: 9 },
  { value: "LuckySpin", key: 10 },
  { value: "Bingo", key: 11 },
  { value: "Offer 1", key: 12 },
  { value: "Offer 2", key: 13 },
  { value: "Offer 3", key: 14 },
];
