<template>
  <div>
    <div v-if="childData.c.findIndex((e) => [1, 3].indexOf(e.t) > -1) > -1">
      <v-toolbar color="orange lighten-1" dark>
        <v-toolbar-title>{{ childData.n }} </v-toolbar-title>
      </v-toolbar>
      <v-flex xs12 md12 v-for="item in childData.c" :key="item.n">
        <v-row v-if="item.t == 1 || item.t == 3">
          <v-col cols="2"
            ><b>
              {{ componentType.find((c) => c.key == item.t).value }}
            </b>
          </v-col>
          <v-col cols="8">
            <div v-if="item.t == 1 || item.t == 3">
              <v-row>
                <v-col cols="11">
                  <v-text-field
                    v-model="item.d.v"
                    label="Value"
                    required
                  ></v-text-field
                ></v-col>
                <v-col cols="1">
                  <v-file-input
                    v-model="pictureUpload"
                    @change="pictureUploadEvent(item)"
                    prepend-icon="mdi-paperclip"
                  >
                  </v-file-input>
                  <v-img v-if="item.d.v" :src="item.d.v" max-width="200" />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-flex>
    </div>
    <dynamic-offer
      :childData="i"
      :key="index"
      v-for="(i, index) in childData.d"
    ></dynamic-offer>
  </div>
</template>
<script>
import componentType from "@/lib/componentType";
export default {
  name: "dynamic-offer",
  data() {
    return {
      componentType: componentType,
      pictureUpload: null,
    };
  },
  props: {
    childData: {
      type: Object,
    },
  },
  methods: {
    async pictureUploadEvent(val) {
      if (this.pictureUpload) {
        let formData = new FormData();
        formData.append("file", this.pictureUpload);
        var result = await this.$store.dispatch("dynamicOffers/fileUpload", {
          data: formData,
        });
        val.d.v = result;
      }
    },
  },
};
</script>
