<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs6 md6>
        <v-btn
          color="red"
          v-if="$store.getters['homes/getEnv'] == 'test'"
          @click="transferProd()"
          >Transfer Production</v-btn
        >
      </v-flex>
      <v-flex xs6 md6>
        <v-btn width="300" color="success" @click="save()">Save</v-btn>
      </v-flex>
      <v-flex xs3 md3>
        <v-text-field
          v-model="dynamicOffer.name"
          label="Name"
          :counter="max"
          required
        ></v-text-field>
      </v-flex>
      <v-flex xs3 md3>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="startDate"
              label="Start Date"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="tr-TR"
            v-model="startDate"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs3 md3>
        <v-menu
          v-model="menu3"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDate"
              label="End Date"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="tr-TR"
            v-model="endDate"
            @input="menu3 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs3 md3>
        <v-switch
          v-model="dynamicOffer.isSegmented"
          label="IsSegmented"
        ></v-switch>
      </v-flex>

      <v-flex xs4 md4>
        <v-combobox
          v-model="$store.state.dynamicOffers.productTypeSelect"
          :items="productTypes"
          item-value="key"
          @change="changeProductTypeSelect"
          item-text="value"
          hide-details
          label="Product Type"
        ></v-combobox>
      </v-flex>

      <v-flex xs4 md4>
        <v-combobox
          v-model="offerTypeSelect"
          :items="$store.state.homes.enumTypes.offerType"
          item-value="value"
          item-text="id"
          hide-details
          label="Offer Type"
        ></v-combobox>
      </v-flex>

      <v-flex xs4 md4>
        <v-text-field
          v-model="dynamicOffer.productCount"
          label="Product Count"
          :counter="max"
          required
          type="number"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md12>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header> Products </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                v-if="products"
                :headers="headers"
                :items="isHideProduct ? products.filter((m) => m.e) : products"
                :items-per-page="5000"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.minSpend="{ item }">
                  <v-text-field
                    v-model="item.minSpend"
                    label="minSpend"
                    :counter="max"
                    required
                  ></v-text-field>
                </template>
                <template v-slot:item.maxSpend="{ item }">
                  <v-text-field
                    v-model="item.maxSpend"
                    label="maxSpend"
                    :counter="max"
                    required
                  ></v-text-field>
                </template>
                <template v-slot:header.e="{ header }">
                  <v-switch @change="changeAllProduct"></v-switch>
                </template>
                <template v-slot:item.e="{ item }">
                  <v-switch v-model="item.e"></v-switch>
                </template>
                <template v-slot:header.sideProducts="{ item }">
                  <v-switch
                    v-model="isHideProduct"
                    label="Show Hidden Product"
                  ></v-switch>
                </template>
                <template v-slot:item.sideProducts="{ item }">
                  <v-data-table
                    :headers="sideProductsHeaders"
                    :items="item.sideProducts"
                    :items-per-page="500"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:item.type="{ item }">
                      <div>
                        {{
                          $store.state.homes.enumTypes.sideProductType.find(
                            (m) => m.value == item.type
                          ).id
                        }}
                      </div>
                    </template>
                    <template v-slot:item.c="{ item }">
                      <div>
                        {{ item.c | formatMoney }}
                      </div>
                    </template>
                    <template v-slot:item.model="{ item }">
                      {{ item.model }}
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)"
                        >edit</v-icon
                      >
                      <v-icon small @click="deleteItem(item)">delete</v-icon>
                    </template>
                  </v-data-table>
                </template>

                <template v-slot:item.vipPoint="{ item }">
                  {{ (item.p * 100) | formatMoney }}
                </template>

                <template v-slot:item.action="{ item }">
                  <v-icon class="mr-2" @click="editProductItem(item)"
                    >edit</v-icon
                  >
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>

      <v-flex xs12 md12>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="storeImage"
              label="storeImage"
              :counter="max"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-file-input
              v-model="storeImageUpload"
              @change="pictureUploadEvent(1)"
              prepend-icon="mdi-paperclip"
            >
            </v-file-input
          ></v-col>
          <v-col cols="3">
            <v-img v-if="storeImage" :src="storeImage" max-width="200" />
          </v-col>
        </v-row>
      </v-flex>
      <v-flex xs12 md12>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="lobbyImage"
              label="lobbyImage"
              :counter="max"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-file-input
              v-model="lobbyImageUpload"
              @change="pictureUploadEvent(2)"
              prepend-icon="mdi-paperclip"
            >
            </v-file-input
          ></v-col>
          <v-col cols="3">
            <v-img v-if="lobbyImage" :src="lobbyImage" max-width="200" />
          </v-col>
        </v-row>
      </v-flex>
      <v-flex xs12 md12>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="purchaseSuccessImage"
              label="purchaseSuccessImage"
              :counter="max"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-file-input
              v-model="purchaseSuccessImageUpload"
              @change="pictureUploadEvent(3)"
              prepend-icon="mdi-paperclip"
            >
            </v-file-input
          ></v-col>
          <v-col cols="3">
            <v-img
              v-if="purchaseSuccessImage"
              :src="purchaseSuccessImage"
              max-width="200"
            />
          </v-col>
        </v-row>
      </v-flex>
      <v-row class="pt-5">
        <v-col cols="12">
          <v-file-input
            v-model="jsonFile"
            color="deep-green accent-4"
            counter
            @change="jsonFileUpload"
            label="Json File"
            placeholder="Select your files"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
          >
          </v-file-input>
        </v-col>
      </v-row>
      <v-flex xs12 md12>
        <div class="text-nowrap" :key="index" v-for="(item, index) in json">
          <dynamic-offer-component :childData="item"></dynamic-offer-component>
        </div>
      </v-flex>
      <v-btn color="success" @click="save()">Save</v-btn>
    </v-layout>
    <side-detail-list-card-modal ref="sideList" />
  </v-container>
</template>
<script>
import DynamicOfferComponent from "../../components/DynamicsOffers/DynamicOffer.vue";

import productType from "../../lib/productType";
import SideDetailListCardModal from "../products/sideProducts/SideDetailListCardModal.vue";
// import router from "@/router";
export default {
  components: { DynamicOfferComponent, SideDetailListCardModal },
  data() {
    return {
      dynamicOffer: {},
      product: {},
      dialog: false,
      name: null,
      productId: null,
      products: null,
      storeImage: null,
      lobbyImage: null,
      purchaseSuccessImage: null,
      isHideProduct: false,
      productTypes: productType,
      storeImageUpload: null,
      lobbyImageUpload: null,
      purchaseSuccessImageUpload: null,
      jsonFile: null,
      json: null,
      alert: false,
      max: 120,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      menu1: false,
      time: null,
      changeSelect: false,
      panel: [0, 1],
      panel2: [0, 1],
      menu2: false,
      menu3: false,
      menu4: false,
      startDate: null,
      offerTypeSelect: { id: "Default", value: 0 },

      endDate: null,
      sideProductsHeaders: [
        { text: "Type", value: "type" },
        { text: "ProductId", value: "productId" },
        { text: "MinSpend", value: "minSpend" },
        { text: "MaxSpend", value: "maxSpend" },
        { text: "Model", value: "model" },
      ],
      headers: [
        { text: "isEnable", value: "e", sortable: false },
        {
          text: "id",
          align: "left",
          value: "i",
        },
        { text: "Price", value: "p" },
        { text: "VipPoint", value: "vipPoint" },
        { text: "MinSpend", value: "minSpend" },
        { text: "MaxSpend", value: "maxSpend" },
        { text: "sideProducts", value: "sideProducts", sortable: false },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },

  methods: {
    changeAllProduct(item) {
      this.products.forEach((element) => {
        element.e = item;
      });
    },
    async pictureUploadEvent(t) {
      let formData = new FormData();
      formData.append(
        "file",
        t == 1
          ? this.storeImageUpload
          : t == 2
          ? this.lobbyImageUpload
          : this.purchaseSuccessImageUpload
      );
      var result = await this.$store.dispatch("dynamicOffers/fileUpload", {
        data: formData,
      });
      if (t == 1) {
        this.storeImage = result;
      } else if (t == 2) {
        this.lobbyImage = result;
      } else {
        this.purchaseSuccessImage = result;
      }
    },
    jsonFileUpload() {
      const fr = new FileReader();
      fr.onload = (e) => {
        this.json = JSON.parse(e.target.result);
      };
      fr.readAsText(this.jsonFile);

      // eslint-disable-next-line no-console
      console.log(this.json);
    },
    addProduct() {
      if (!this.dynamicOffer.products) this.dynamicOffer.products = [];
      var ind = this.dynamicOffer.products.findIndex(
        (m) => m.id == this.product.id
      );
      if (ind > -1) {
        this.dynamicOffer.products[ind] = this.product;
      } else this.dynamicOffer.products.push(this.product);
      this.product = {};
    },
    editProductItem(item) {
      this.product = item;
      this.productId = item.i;
      this.dialog = true;
      // eslint-disable-next-line no-console
      console.log(this.$refs.sideList);
      this.$refs.sideList.initialize(
        item.i,
        this.dynamicOffer.id,
        this.callback
      );
    },
    async callback() {
      await this.$store.dispatch("dynamicOffers/get", this.$route.params.id);
      this.products = this.$store.state.dynamicOffers.detail.products.filter(
        (m) => m.t == this.dynamicOffer.productType
      );
    },
    deleteProductItem(item) {
      this.dynamicOffer.products.splice(
        this.dynamicOffer.products.indexOf(item),
        1
      );
      this.dynamicOffer.products = [...this.dynamicOffer.products];
    },

    remove(item) {
      this.dynamicOffer.userIds.splice(
        this.dynamicOffer.userIds.indexOf(item),
        1
      );
      this.dynamicOffer.userIds = [...this.dynamicOffer.userIds];
    },
    removeSegment(item) {
      this.dynamicOffer.segments.splice(
        this.dynamicOffer.segments.indexOf(item),
        1
      );
      this.dynamicOffer.segments = [...this.dynamicOffer.segments];
    },
    changeProductTypeSelect() {
      this.products = this.$store.state.dynamicOffers.detail.products.filter(
        (m) => m.t == this.$store.state.dynamicOffers.productTypeSelect.key
      );
    },
    async transferProd() {
      await this.$store.dispatch(
        "dynamicOffers/transferProd",
        this.$store.state.dynamicOffers.detail
      );
      this.$store.dispatch("setAlert");
    },
    async save() {
      this.dynamicOffer.minSpend = parseInt(this.dynamicOffer.minSpend);
      this.dynamicOffer.maxSpend = parseInt(this.dynamicOffer.maxSpend);
      this.dynamicOffer.startTime = new Date(this.startDate).getTime() / 1000;
      this.dynamicOffer.expireTime = new Date(this.endDate).getTime() / 1000;
      this.dynamicOffer.storeImage = this.storeImage;
      this.dynamicOffer.lobbyImage = this.lobbyImage;
      this.dynamicOffer.purchaseSuccessImage = this.purchaseSuccessImage;
      this.dynamicOffer.type = this.offerTypeSelect.value;
      this.dynamicOffer.productType =
        this.$store.state.dynamicOffers.productTypeSelect.key;
      this.dynamicOffer.json = JSON.stringify(this.json);
      await this.$store.dispatch("dynamicOffers/createOrUpdate", {
        dynamicOffer: this.dynamicOffer,
        products: this.products,
      });
      this.$store.dispatch("setAlert");

      // router.push("/dynamicOffers");
    },
    validateField() {
      this.$refs.form.validate();
    },
  },
  computed: {},

  async created() {
    await this.$store.dispatch("dynamicOffers/get", this.$route.params.id);
    this.dynamicOffer = this.$store.state.dynamicOffers.detail.dynamicOffer;
    this.products = this.$store.state.dynamicOffers.detail.products.filter(
      (m) => m.t == this.dynamicOffer.productType
    );

    this.offerTypeSelect = this.$store.state.homes.enumTypes.offerType.find(
      (m) => m.value == this.dynamicOffer.type
    );

    this.storeImage = this.dynamicOffer.storeImage;
    this.lobbyImage = this.dynamicOffer.lobbyImage;
    this.purchaseSuccessImage = this.dynamicOffer.purchaseSuccessImage;

    this.json = JSON.parse(this.dynamicOffer.json);
    this.startDate = new Date(this.dynamicOffer.startTime * 1000)
      .toISOString()
      .substr(0, 10);
    this.endDate = new Date(this.dynamicOffer.expireTime * 1000)
      .toISOString()
      .substr(0, 10);
    this.$store.state.dynamicOffers.productTypeSelect = this.productTypes.find(
      (m) => m.key == this.dynamicOffer.productType
    );
  },
};
</script>
